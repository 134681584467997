<template>
    <section class="page-section">
        <div class="container">
            <div class="grid mb-4 card nice-shadow-6 justify-content-between align-items-center">
                <div class="col ">
                    <div class="">
                        <div class="flex align-items-center ">
                            <div class="mr-3">
                                <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                                    class="p-button-sm p-button-rounded px-4" />
                            </div>
                            <div>
                                <div class="text-2xl text-primary font-bold">Contact Us</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="grid justify-content-center">
            <div class="col-12 md:col-12">
                <div class="card mt-1">
                    <div class="text-xl my-4 font-bold">
                        Phone: 0544975461
                    </div>
                    <div class="text-xl my-4 font-bold">
                        Email: fredmakhostel@gmail.com
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>